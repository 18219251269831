import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import infoRouter from './modules/info'
const routes = [
  ...infoRouter,
  {
    path: '/wechatH5Auth',
    name: 'wechatH5Auth',
    component: () => import('@/views/wechatAuth/authIndex.vue'),
    meta: {
      title: '微信授权',
      hideFoot: true
    }
  },
  {
    path: '/wechatAuthResult',
    name: 'wechatAuthResult',
    component: () => import('@/views/wechatAuth/authResult.vue'),
    meta: {
      title: '微信授权结果',
      hideFoot: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
// 解决vue中的NavigationDuplicated {_name: "NavigationDuplicated", name: "NavigationDuplicated"}
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
