const infoRouter = [
  {
    path: '/',
    name: 'info',
    component: () => import('@/views/leaflets/index_new.vue'),
    meta: {
      title: '金来客-企业微信服务商',
      content: {
        keywords: '企业微信营销,社群运营、企业微信SCRM,私域流量运营,微信营销软件,金来客',
        description: '金来客-企业微信服务商，提供微信营销,私域运营全套工具，包括引流获客、客户跟进转化、社群运营等全方位工具，帮助企业构建高转化率的私域流量池。'
      },
      hideFoot: true
    }
  },
  {
    path: '/aboutUs',
    name: 'info',
    component: () => import('@/views/leaflets/aboutUs.vue'),
    meta: {
      title: '金来客-关于我们',
      content: {
        keywords: '企业微信营销,社群运营、企业微信SCRM,私域流量运营,微信营销软件,金来客',
        description: '金来客-企业微信服务商，提供微信营销,私域运营全套工具，包括引流获客、客户跟进转化、社群运营等全方位工具，帮助企业构建高转化率的私域流量池。'
      },
      hideFoot: true
    }
  }
]

export default infoRouter
