<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {

}
</script>>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333;
  margin: 0px;
  padding: 0;
  min-height: 100%;
  font-size: 14px;
}
html,body{
    height:100%;
    width: 100%;
    background: #ffffff ;
}
body{
  margin: 0;
}
*{
  margin: 0;
  padding: 0;
}
p{
  margin: 0;
}
a{
  text-decoration: none;
  color: #ccc ;
}
</style>
